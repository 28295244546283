import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DisplayPrices from "./DisplayPrices";
import Drawer from "./Drawer";
import ListPriceModal from "./ListPriceModal";
import useMarkups from "./useMarkups";
import markups_PT from "../data/partstownMarkups";
import markups_CP from "../data/vendorMarkups";
import toast from "react-hot-toast";

const SelectVendor = ({ vendor, tier, isManager, isPartstown }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [cost, setCost] = useState("");
  const [listPrice, setListPrice] = useState("");
  const [isEnteringCost, setIsEnteringCost] = useState(
    !vendor.PromptForListPrice
  );
  const [overridePrice, setOverridePrice] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [markups, setMarkups] = useState(isPartstown ? markups_PT : markups_CP);
  const {
    calculatePrices,
    calcEndUserMarkup,
    calcDealerMarkup,
    calcWholesalerMarkup,
  } = useMarkups(markups);
  const costInputRef = useRef(null);
  const listPriceInputRef = useRef(null);

  useEffect(() => {
    const show = JSON.parse(
      localStorage.getItem("CPC.spo-pricing-details") || "false"
    );
    setShowDetails(show);
  }, []);

  useEffect(() => {
    setMarkups(isPartstown ? markups_PT : markups_CP);
  }, [isPartstown]);

  useEffect(() => {
    if (vendor) {
      setCost("");
      setListPrice("");
      setOverridePrice(null);
      setTimeout(() => {
        if (isEnteringCost) {
          costInputRef.current?.focus();
        } else {
          listPriceInputRef.current?.focus();
        }
      }, 0);
    }
  }, [vendor, isEnteringCost]);

  useEffect(() => {
    setIsEnteringCost(!vendor.PromptForListPrice);
  }, [vendor.PromptForListPrice]);

  const handleToggleDetails = () => {
    const newShowDetails = !showDetails;
    localStorage.setItem(
      "CPC.spo-pricing-details",
      JSON.stringify(newShowDetails)
    );
    setShowDetails(newShowDetails);
  };

  const handleCostChange = (event) => {
    const costValue = Number(event.target.value);
    setCost(costValue);
    if (costValue > 0 && vendor?.Discount) {
      const calculatedListPrice = costValue / (1 - vendor.Discount);
      setListPrice(calculatedListPrice.toFixed(2));
    } else if (vendor.Discount === 0) {
      setListPrice(costValue);
    } else {
      setListPrice("");
    }
  };

  const handleListPriceChange = (event) => {
    const listPriceValue = Number(event.target.value);
    setListPrice(listPriceValue);
    if (listPriceValue > 0 && vendor?.Discount) {
      const calculatedCost = listPriceValue * (1 - vendor.Discount);
      setCost(calculatedCost.toFixed(2));
    } else if (vendor.Discount === 0) {
      setCost(listPriceValue.toFixed(2));
    } else {
      setCost("");
    }
  };

  const handleListPriceSubmit = (price) => {
    setOverridePrice(price > cost ? price : null);
  };

  const numericCost = parseFloat(cost) || 0;
  const numericListPrice = parseFloat(listPrice) || 0;
  const numericOverridePrice = parseFloat(overridePrice);

  const vendorDiscount = vendor?.Discount || 0;

  let actualDiscount = vendorDiscount;
  if (
    isEnteringCost &&
    !isNaN(numericOverridePrice) &&
    numericOverridePrice !== 0
  ) {
    actualDiscount = 1 - numericCost / numericOverridePrice;
  } else if (!isEnteringCost && numericListPrice > 0) {
    actualDiscount = 1 - numericCost / numericListPrice;
  }

  const prices = calculatePrices(
    numericCost || numericListPrice,
    actualDiscount
  );

  const formatNumber = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "0.00" : number.toFixed(2);
  };

  const pre = (c) => `part-pricing__${c}`;

  const handleCalculatedClick = () => {
    if (isEnteringCost) {
      // Open modal to enter actual list price
      setModalOpen(true);
    } else {
      // Copy calculated cost to clipboard
      const formattedCost = formatNumber(numericCost);
      navigator.clipboard.writeText(formattedCost);
      toast.success(`Copied $${formattedCost}`, {
        position: "bottom-center",
        icon: "📄",
        style: { backgroundColor: "var(--neutral-6)", color: "white" },
      });
    }
  };

  const hasCustomMarkups =
    actualDiscount === vendorDiscount &&
    (vendor.EndUserMarkup || vendor.DealerMarkup || vendor.WholesalerMarkup);

  const MarkupDisplay = ({
    tier,
    actualMarkup,
    calculatedMarkupFunc,
    discount,
    vendorDiscount,
  }) => {
    const calculatedMarkup = calculatedMarkupFunc(discount);
    const showComparison =
      actualMarkup != null &&
      vendorDiscount === discount &&
      actualMarkup !== calculatedMarkup;

    return (
      <div>
        <label>{tier} Markup:</label>
        <p>
          {showComparison ? (
            <>
              {formatNumber(actualMarkup)}
              <span className={pre("versus")}>
                {" "}
                vs {formatNumber(calculatedMarkup)}
              </span>
            </>
          ) : (
            formatNumber(calculatedMarkup)
          )}
        </p>
      </div>
    );
  };

  const tiers = [
    { name: "EndUser", calcFunc: calcEndUserMarkup },
    { name: "Dealer", calcFunc: calcDealerMarkup },
    { name: "Wholesaler", calcFunc: calcWholesalerMarkup },
  ];

  return (
    <div className={pre("section")}>
      {vendor ? (
        <>
          {vendor.Vendor.startsWith(">>>") && (
            <div className={pre("nonDefault")}>
              <p>
                Use this when pricing a quote from a vendor not yet configured
                in this tool.
              </p>
              <p>
                Start by entering our <b>Cost</b>. Then, edit <b>List Price</b>{" "}
                to match your quote.
              </p>
            </div>
          )}
          <div className={pre("input")}>
            {isEnteringCost ? (
              <>
                <label>
                  Cost:
                  <Link
                    to="#"
                    onClick={() => setIsEnteringCost(false)}
                    className={pre("input-toggle")}
                  >
                    Enter List Price instead
                  </Link>
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    className={pre("csr-input")}
                    type="number"
                    value={cost}
                    onChange={handleCostChange}
                    placeholder="Enter cost"
                    ref={costInputRef}
                  />
                  {(numericOverridePrice > 0 || numericListPrice > 0) && (
                    <span
                      className={`${pre("calculated")} ${
                        overridePrice ? "overridePrice" : ""
                      }`}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={handleCalculatedClick}
                    >
                      List Price: $
                      {formatNumber(overridePrice || numericListPrice)}
                      <span className={pre("edit")}>
                        <Link to="#">Edit</Link>
                      </span>
                    </span>
                  )}
                </div>
              </>
            ) : (
              <>
                <label>
                  List Price:
                  <Link
                    to="#"
                    onClick={() => setIsEnteringCost(true)}
                    className={pre("input-toggle")}
                  >
                    Enter Cost instead
                  </Link>
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    className={pre("csr-input")}
                    type="number"
                    value={listPrice}
                    onChange={handleListPriceChange}
                    placeholder="Enter list price"
                    ref={listPriceInputRef}
                  />
                  {numericCost > 0 && (
                    <span
                      className={`${pre("calculated")}`}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={handleCalculatedClick}
                    >
                      Cost: ${formatNumber(numericCost)}
                    </span>
                  )}
                </div>
              </>
            )}
            {!!vendor.MinOrder && (
              <div className={pre("note")}>
                <span>Minimum Order:</span> {vendor.MinOrder}
              </div>
            )}
            {!!vendor.Note && (
              <div className={pre("note")}>
                <span>Note:</span> {vendor.Note}
              </div>
            )}

            {prices && <DisplayPrices prices={prices} tier={tier} />}

            {isManager && (
              <Drawer isOpen={showDetails} setIsOpen={handleToggleDetails}>
                <div className={pre("pricing-details")}>
                  <h3>
                    Pricing based on {hasCustomMarkups ? "custom" : "standard"}{" "}
                    markups
                  </h3>
                  <div>
                    <label>Manufacturer:</label>
                    <p>{vendor.Vendor}</p>
                  </div>
                  <div>
                    <label>Discount:</label>
                    <p>{(vendorDiscount * 100).toFixed(2)}%</p>
                  </div>
                  {actualDiscount !== vendorDiscount && (
                    <div className="overridePrice">
                      <label>Actual Discount:</label>
                      <p>{(actualDiscount * 100).toFixed(2)}%</p>
                    </div>
                  )}
                  {tiers.map(({ name, calcFunc }) => (
                    <MarkupDisplay
                      key={name}
                      tier={name}
                      actualMarkup={vendor[`${name}Markup`]}
                      calculatedMarkupFunc={calcFunc}
                      discount={actualDiscount}
                      vendorDiscount={vendorDiscount}
                    />
                  ))}{" "}
                </div>
                <div className="nerdy-details">
                  <div>
                    <Link
                      to={"/pricing" + (isPartstown ? "?pt" : "")}
                      className="review-markups-link"
                    >
                      Pricing Table
                    </Link>
                  </div>
                </div>
              </Drawer>
            )}

            <ListPriceModal
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onSubmit={handleListPriceSubmit}
              initialPrice={overridePrice}
            />
          </div>
        </>
      ) : (
        <h1>Please select a vendor to proceed</h1>
      )}
    </div>
  );
};

export default SelectVendor;
