import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "./tokenUtils"; // Import utility
import { jwtDecode } from "jwt-decode";

// Higher-Order Component for authentication
const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState();
    const [initialized, setInitialized] = useState(false);
    const [claims, setClaims] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
      const token = localStorage.getItem("inviteToken");
      const decodedUserInfo = token ? decodeToken(token) : null;

      if (decodedUserInfo) {
        setUserInfo(decodedUserInfo);
        setInitialized(true);
      } else {
        fetchToken().catch((e) => {
          console.error("Error fetching token:", e);
          setError(e.message);
          setInitialized(true);
        });
      }
    }, []);

    useEffect(() => {
      if (initialized && !userInfo && claims.UserType !== "Employee") {
        navigate("/unauthorized");
      }
    }, [initialized, userInfo, claims, navigate]);

    if (!initialized) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>{error}</div>;
    }

    // Define isManager boolean
    const isManager =
      claims.Employee?.Rights?.includes("SPODetails") || userInfo;

    return <WrappedComponent {...props} isManager={isManager} />;

    // Extends Error to include statusCode
    class FetchError extends Error {
      constructor(statusCode, message) {
        super(message);
        this.name = "FetchError";
        this.statusCode = statusCode;
      }
    }

    // Provides default error messages for common status codes
    function defaultErrorMessage(status, url) {
      switch (status) {
        case 401:
          return "You are not authenticated";
        case 403:
          return "You do not have permission to access this page";
        case 404:
          return "Page does not exist: " + url;
        default:
          return "Unexpected Error";
      }
    }

    // Check authentication if no invite to support CP employees
    function fetchToken() {
      const options = {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ApiKey: process.env.REACT_APP_APIKEY,
        },
      };

      const url = "https://my.caseparts.com/core/api/authenticate/login";
      return fancyFetch(url, options)
        .then((res) => (res ? res.text() : null))
        .then((jwt) => (jwt ? jwt.replace(/['"]+/g, "") : null))
        .then((jwt) => {
          assignToken(jwt);
        });
    }

    function fancyFetch(url, options) {
      return fetch(url, options)
        .then(async (res) => {
          if (res.status < 400) {
            return res;
          } else {
            let text = await res.text();
            var message = text
              ? String(text).replace(/['"]+/g, "")
              : res.statusText
              ? res.statusText
              : defaultErrorMessage(res.status, url);
            throw new FetchError(res.status, message);
          }
        })
        .catch((e) => {
          if (e.statusCode === 403) {
            navigate("/unauthorized");
          } else {
            throw e;
          }
        });
    }

    function assignToken(jwt) {
      var claims = jwtDecode(jwt);
      parseNested(claims, "Customer");
      parseNested(claims, "Employee");
      if (claims.Employee && claims.Employee.Rights) {
        const rights = claims.Employee.Rights.split(", ");
        claims.Employee.Rights = rights;
      }
      setClaims(claims);
      setInitialized(true);
    }

    function parseNested(claims, name) {
      if (
        claims &&
        typeof claims[name] === "string" &&
        claims[name].length > 0
      ) {
        claims[name] = JSON.parse(claims[name]);
      }
    }
  };
};

export default withAuth;
