import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import discounts_PT from "../data/partstown.json";
import discounts_CP from "../data/vendors";
import SelectVendor from "./SelectVendor";
import iconCP from "../assets/CP.webp";
import iconPT from "../assets/PT.webp";

const pre = (c) => "part-pricing__" + c;

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#dfdfdf",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#707070",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f6f6f6" : "#fff",
    color: "#2d2d2d",
    "&:hover": {
      backgroundColor: "#f6f6f6",
    },
  }),
};

// Prepare options
const pt_options = discounts_PT.map((row, index) => {
  return {
    value: `pt-${index}`,
    label: row.Vendor,
    Vendor: row.Vendor,
    Discount: row.Discount,
    AvgDiscount: null,
    isPartstown: true,
    index: index,
    ...row,
  };
});

const cp_options = discounts_CP.map((row, index) => {
  return {
    value: `cp-${index}`,
    label: row.Vendor,
    Vendor: row.Vendor,
    Discount: row.Discount || row.AvgDiscount || 0,
    AvgDiscount: row.AvgDiscount,
    EndUserMarkup: row.EndUserMarkup,
    DealerMarkup: row.DealerMarkup,
    WholesalerMarkup: row.WholesalerMarkup,
    MinOrder: row.MinOrder,
    Note: row.Note || "",
    isPartstown: false,
    index: index,
    ...row,
  };
});

const options = [...pt_options, ...cp_options].sort((a, b) => {
  const comparison = a.Vendor.toLowerCase().localeCompare(
    b.Vendor.toLowerCase()
  );
  return comparison !== 0 ? comparison : a.isPartstown ? 1 : -1;
});

const formatDiscount = (discount, avgDiscount) => {
  if (Number.isNaN(discount) || discount == null) return "";
  const prefix = avgDiscount ? "≈" : "";
  return prefix + (discount * 100).toFixed(1) + "% discount";
};

function toTitleCase(str) {
  const specialWords = {
    aka: "aka",
    apw: "APW",
    for: "for",
    "3m": "3M",
    hk: "HK",
    htpg: "HTPG",
    qdb: "QDB",
    oem: "OEM",
    menumaster: "MenuMaster",
    jmcfoodequipment: "JMCFoodEquipment",
  };

  return str
    .toLowerCase()
    .split(/[\s()&]+/)
    .map((word) => {
      const lowerWord = word.toLowerCase();
      if (specialWords[lowerWord]) {
        return specialWords[lowerWord];
      }

      return word
        .split("-")
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join("-");
    })
    .join(" ");
}

const formatOptionLabel = ({ Vendor, Discount, AvgDiscount, isPartstown }) => (
  <div>
    <div style={{ float: "left", marginRight: ".5rem" }}>
      <img
        src={isPartstown ? iconPT : iconCP}
        width={24}
        alt={isPartstown ? "Partstown" : "CaseParts"}
      />
    </div>
    <div>
      <strong> &nbsp;&nbsp;{toTitleCase(Vendor)}</strong> &nbsp;&nbsp;
      <span style={{ fontSize: ".75rem", fontStyle: "italic" }}>
        <small>{formatDiscount(Discount, AvgDiscount)}</small>
      </span>
    </div>
  </div>
);

const DropdownIndicator = (props) => {
  const { selectProps } = props;
  const rotation = selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)";

  return (
    <components.DropdownIndicator {...props}>
      <div
        style={{
          transform: rotation,
          transition: "transform 0.3s ease",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 20 20"
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            d="M10.103 12.778L16.81 6.08a.69.69 0 0 1 .99.012a.726.726 0 0 1-.012 1.012l-7.203 7.193a.69.69 0 0 1-.985-.006L2.205 6.72a.727.727 0 0 1 0-1.01a.69.69 0 0 1 .99 0z"
          />
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};

const UnifiedSelect = ({ search, tier, isManager }) => {
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [searchString, setSearchString] = useState(search || "");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    setSearchString(search || "");
    if (search) {
      setMenuIsOpen(true);
    }
  }, [search]);

  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.isFallback) {
      selectFirstOption();
    } else {
      setSelectedVendor(selectedOption);
    }
    if (!selectedOption) {
      setMenuIsOpen(false);
    }
  };

  const handleInputChange = (value) => {
    setSearchString(value);
    setMenuIsOpen(!!value);
  };

  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <div className={pre("notFound")} onClick={() => selectFirstOption()}>
        <h3>No vendor found matching "{props.selectProps.inputValue}"</h3>
        <p>
          Edit your search text or click below if this is an uncommon
          make/vendor not yet set up in this tool.
        </p>
        <button>
          Price a <b>non-standard make</b>
        </button>
      </div>
    </components.NoOptionsMessage>
  );

  const selectFirstOption = () => {
    // Set the first option as the selected value in both state and React-Select
    const firstOption = options[0];
    setSelectedVendor(firstOption);
    setSearchString(firstOption.label);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchString.toLowerCase())
  );

  return (
    <div>
      <Select
        options={filteredOptions}
        components={{ DropdownIndicator, NoOptionsMessage }}
        value={selectedVendor} // Ensure the selected option is displayed
        onChange={handleChange}
        isClearable
        placeholder="Start typing to search for vendor..."
        inputValue={searchString}
        onInputChange={handleInputChange}
        styles={customStyles}
        classNamePrefix={"react-select"}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        formatOptionLabel={formatOptionLabel}
        isSearchable={true}
        getOptionValue={(option) => option.value}
      />

      {selectedVendor && (
        <SelectVendor
          vendor={
            selectedVendor.isPartstown
              ? discounts_PT[selectedVendor.index]
              : discounts_CP[selectedVendor.index]
          }
          tier={tier}
          isManager={isManager}
          isPartstown={selectedVendor.isPartstown}
        />
      )}
    </div>
  );
};

export default UnifiedSelect;
